import {Link} from "gatsby";
import React, {useState} from "react";
import {
  incrementWithOverflow,
  decrementWithOverflow,
} from "src/helpers/numbers";
import ResponsiveGatsbyImage from "src/components/common/responsive_image";
import Carousel from "src/components/common/carousel";

export default function ValueBlock(props) {
  const [selectedItemId, setSelectedItemId] = useState(1);

  const imageBlock = (imageName, allImages, alt, textFirst, isSelected) => (
    <div
      className={`transition-opacity duration-500 ${
        isSelected ? "opacity-100" : "opacity-0"
      } col-start-1 col-end-1 row-start-1 row-end-1`}
    >
      <ResponsiveGatsbyImage
        alt={alt}
        files={allImages}
        image={imageName}
        desktopImageClassName={`w-full ${
          textFirst ? "rounded-r-3xl" : "rounded-l-3xl"
        }`}
        mobileImageClassName="w-full rounded-t-3xl"
      />
    </div>
  );

  const carouselBlock = (carouselItems, allImages, textFirst) => (
    <Carousel
      items={carouselItems.map((carouselItem, carouselItemIndex) => (
        <ResponsiveGatsbyImage
          alt={carouselItem.alt}
          files={allImages}
          image={carouselItem.imageName}
          desktopImageClassName={`w-full ${
            textFirst ? "rounded-r-3xl" : "rounded-l-3xl"
          }`}
          mobileImageClassName="w-full rounded-t-3xl"
        />
      ))}
    />
  );

  const imagesBlock = (items, allImages, textFirst, selectedItemId) => (
    <div className="flex-1 grid grid-cols-1 grid-rows-1">
      {items.map((el, index) => {
        if (el.carouselItems !== undefined) {
          return carouselBlock(el.carouselItems, allImages, textFirst);
        } else {
          return imageBlock(
            el.imageName,
            allImages,
            el.alt,
            textFirst,
            index === selectedItemId
          );
        }
      })}
    </div>
  );
  const learnMoreButton = (url) => (
    <Link to={url} className="rounded-full py-4 px-8 bg-black text-white">
      Learn more
    </Link>
  );

  const switcherBlock = (totalItems, detailsUrl) => (
    <div
      className={`${
        totalItems > 1 ? "block" : "hidden"
      } flex flex-row justify-between md:justify-between items-center mb-4 mt-0 md:mt-4 md:mb-0 h-8`}
    >
      <div className="hidden md:flex shrink-0">
        {detailsUrl != undefined ? learnMoreButton(detailsUrl) : <div />}
      </div>
      <div className=" flex flex-row justify-between md:justify-end w-full md:w-max">
        <div className="mr-2">
          <span className="font-medium text-2xl">
            {String(selectedItemId).padStart(2, "0")}
          </span>
          <span className="text-accent-red mx-1">/</span>
          <span className="text-gray-600">
            {String(totalItems).padStart(2, "0")}
          </span>
        </div>
        <div className="flex flex-row">
          <a
            onClick={() =>
              setSelectedItemId(
                decrementWithOverflow(selectedItemId, totalItems)
              )
            }
          >
            <img
              className="mx-3 cursor-pointer"
              width="30"
              src={require("src/images/common/arrow_left.svg").default}
              alt="Arrow pointing left"
            />
          </a>
          <a
            onClick={() =>
              setSelectedItemId(
                incrementWithOverflow(selectedItemId, totalItems)
              )
            }
          >
            <img
              className="mx-3 cursor-pointer"
              width="30"
              src={require("src/images/common/arrow_right.svg").default}
              alt="Arrow pointing right"
            />
          </a>
        </div>
      </div>
    </div>
  );

  const auxiliaryImagesBlock = (auxiliaryImages) => {
    if (auxiliaryImages !== undefined) {
      return (
        <div className="mt-4 flex flex-row items-center justify-around">
          {(auxiliaryImages || []).map((el) => (
            <div className="flex-shrink mx-2">
              <img
                key={`auxImage-${el.imageName}`}
                width={el.width}
                height={el.height}
                src={require("src/images/pages/" + el.imagePath).default}
                alt={el.alt}
              />
            </div>
          ))}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const textBlock = (item, itemsLength) => (
    <div className="flex-1 font-light">
      <div className="flex flex justify-between flex-col-reverse md:flex-col p-6 md:p-8 lg:p-16 h-full">
        <div className="flex md:hidden mt-4 fit self-center">
          {item.detailsUrl != undefined ? (
            learnMoreButton(item.detailsUrl)
          ) : (
            <div />
          )}
        </div>
        <div>
          {item.headerImage != undefined ? (
            <img
              width="60"
              height="60"
              src={
                require("src/images/pages/" + item.headerImage.image).default
              }
              alt={item.headerImage.alt}
              className="mb-2 md:mb-4"
            />
          ) : (
            <div />
          )}
          <h1
            className="mb-6 section-title"
            dangerouslySetInnerHTML={{__html: item.title}}
          />
          {/* <!-- Desktop --> */}
          <div
            className="hidden md:block font-light text-base md:text-sm lg:text-base"
            dangerouslySetInnerHTML={{__html: item.text}}
          />
          {/* <!-- Mobile --> */}
          <div
            className="block md:hidden font-light text-base md:text-sm lg:text-base"
            dangerouslySetInnerHTML={{__html: item.textMobile || item.text}}
          />
          {auxiliaryImagesBlock(item.auxiliaryImages)}
        </div>
        <div>{switcherBlock(itemsLength, item.detailsUrl)}</div>
      </div>
    </div>
  );
  const currentItem = props.items[selectedItemId - 1];
  return (
    <div className="p-4 lg:p-8 w-full">
      {/* <!-- Desktop --> */}
      <div
        className={`hidden md:flex ${
          props.textFirst ? "flex-row" : "flex-row-reverse"
        } bg-light-gray rounded-3xl`}
      >
        {textBlock(currentItem, props.items.length)}
        {imagesBlock(
          props.items,
          props.allImages,
          props.textFirst,
          selectedItemId - 1
        )}
      </div>
      {/* <!-- Mobile --> */}
      <div className="flex md:hidden flex-col bg-light-gray rounded-3xl">
        {imagesBlock(
          props.items,
          props.allImages,
          props.textFirst,
          selectedItemId - 1
        )}
        {textBlock(currentItem, props.items.length)}
      </div>
    </div>
  );
}
