import React, {useState, useEffect} from "react";
import {incrementWithOverflow} from "src/helpers/numbers";

export default function Carousel(props) {
  const activateTimer = () =>
    setTimerId(
      setInterval(() => {
        setSelectedItemId((selectedId) =>
          incrementWithOverflow(selectedId, props.items.length)
        );
      }, 4000)
    );

  const [selectedItemId, setSelectedItemId] = useState(1);

  const [timerId, setTimerId] = useState(undefined);
  if (timerId === undefined) {
    activateTimer();
  }

  useEffect(() => {
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex-1 grid grid-cols-1 grid-rows-1">
        {props.items.map((item, index) => (
          <div
            className={`transition-opacity duration-500 ${
              index + 1 === selectedItemId ? "opacity-100" : "opacity-0"
            } col-start-1 col-end-1 row-start-1 row-end-1`}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="absolute inset-x-0 bottom-4 flex flex-row items-center justify-center flex-wrap space-x-2 px-8">
        {props.items.map((_, index) => (
          <div
            className={`cursor-pointer w-2 h-2 bg-black rounded ${
              selectedItemId === index + 1 ? "opacity-100" : "opacity-30"
            }`}
            onClick={() => {
              setSelectedItemId(index + 1);
              clearInterval(timerId);
            }}
          />
        ))}
      </div>
    </div>
  );
}
